import { BASE_URL } from './baseUrl';
import {NetworkRequestError} from './error';
import {TMetadata} from '../metadata';
import { TTag } from './tags';
import { LGTContractVersion } from './contract';

export interface TTemplate {
  id: number;
  updated_at: number;
  created_at: number;
  name?: string;
  description?: string;
  image_url?: string;
  animation_url?: string;
  metadata: TMetadata;
  nft_chain_id?: number;
  nft_contract_address?: string;
  tags_count: number;
  redirect_url?: string;
  notes?: string;
  contract_version?: LGTContractVersion;
  auto_manage_tag_sequence: boolean;
  analytics_url?: string;
  tag_verifications_count: number;
  tag_view_verifications_count: number;
  verified_tags_count: number;
  login_type: 0 | 1 | 2;
  header_logo_url?: string;
  builder_io_api_key?: string;
  builder_io_url?: string;
}

export type TCreateTemplateParams = Partial<
  Pick<
    TTemplate,
    'name' |
    'description' |
    'image_url' |
    'animation_url' |
    'metadata' |
    'notes' |
    'redirect_url' |
    'nft_chain_id' |
    'auto_manage_tag_sequence' |
    'contract_version'
  >
>;

export type TUpdateTemplateParams = TCreateTemplateParams;

export const getTemplates = async (
  authToken: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/templates`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTemplate[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const createTemplate = async (
  authToken: string,
  template: TCreateTemplateParams,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/templates`, {
      method: 'POST',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        template,
      }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTemplate = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const getTemplate = async (
  authToken: string,
  templateId: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/templates/${templateId}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTemplate = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};


export const updateTemplate = async (
  authToken: string,
  templateId: number,
  params: TUpdateTemplateParams,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(
      `${serverBaseUrl || BASE_URL.PRODUCTION}/templates/${templateId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          template: params,
        }),
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTemplate = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const deleteTemplate = async (
  authToken: string,
  templateId: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(
      `${serverBaseUrl || BASE_URL.PRODUCTION}/templates/${templateId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
  } catch (e) {
    throw e;
  }
};

export const transferTemplate = async (
  authToken: string,
  templateId: number,
  userId: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(
      `${serverBaseUrl || BASE_URL.PRODUCTION}/templates/${templateId}/transfer`,
      {
        method: 'POST',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
  } catch (e) {
    throw e;
  }
};

export const activateTagsInTemplate = async (
  authToken: string,
  templateId: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(
      `${serverBaseUrl || BASE_URL.PRODUCTION}/templates/${templateId}/activate`,
      {
        method: 'POST',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};
