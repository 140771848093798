import { Box, Container, Typography } from "@mui/material"
import { useContext } from "react"
import { AuthContext } from "../utils/auth/useAuth";
import { DataGrid } from "@mui/x-data-grid";
import { getUsers } from "../utils/network/users";
import { useQuery } from "@tanstack/react-query";

const columns = [
  { field: 'id', headerName: 'User ID', width: 90, type: 'number' },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 180,
    type: 'dateTime',
    valueGetter: (params: any) => {
      return new Date(params.row.created_at);
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    type: 'string',
  },
  {
    field: 'templates_count',
    headerName: 'SKUs',
    width: 80,
    type: 'number',
  },
  {
    field: 'tags_count',
    headerName: 'Tags',
    width: 100,
    type: 'number',
  },
  {
    field: 'tag_verifications_count',
    headerName: 'Tag Verifications',
    width: 150,
    type: 'number',
  },
  {
    field: 'external_tag_verifications_count',
    headerName: 'API Tag Verifications',
    width: 250,
    type: 'number',
  },
]

const Users = () => {
  const { userAuth } = useContext(AuthContext);

  const { data, isLoading } = useQuery({
    queryKey: ['Users'],
    queryFn: () => getUsers(userAuth?.token || ''),
  })

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Users</Typography>
      <Box sx={{ height: 20, width: '100%' }} />
      <DataGrid
        autoHeight
        rows={data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default Users
