import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const PINATA_UPLOAD_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJlMTNiYzM5ZC05NDczLTQxMzAtYjkxZi1hYmJhMTA5ZTBiZDgiLCJlbWFpbCI6ImNhbHZpbkBsZWdpdGltYXRlLnRlY2giLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxLCJsYXN0X21pZ3JhdGVkIjoxNjUxOTIyNjc1ODg2fSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMDFjMDcyYWY4ODRlOWQwZWM1NGYiLCJzY29wZWRLZXlTZWNyZXQiOiJiYjA5NTFlYTY4NzBiYzY1YTFmYjRiMWVjNmFlOTc0ZjJhMWYwN2Q3ZGQ1YTFhMTUyMjNjZDhhZjJlNGQwY2Y5IiwiaWF0IjoxNzA4MzM0Njk5fQ.eY8xwlBW5vnnY88TZiUiA9L6-Gc7dQh1g38hS_mUrCI'
const LGT_IPFS_GATEWAY_PREFIX = 'https://ipfs.legitimate.tech/ipfs/'

const UploadAdornment = ({ onUpload, url, accept, ...props }: { onUpload: (url: string) => void, url: string, accept: string }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleUpload = useCallback(async () => {
    if (inputRef.current) {
      setLoading(true);
      const file = inputRef.current.files?.[0];
      if (file) {
        if (file.size > 10485760) {
          alert('Your file size is large and may load slowly. We recommend keeping the file under 10MB. Please optimize by compressing or resizing it.');
        }

        try {
          const formData = new FormData();
          formData.append('file', file, file.name);
          const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `Bearer ${PINATA_UPLOAD_KEY}`,
            }
          });
          const { IpfsHash } = await response.json();

          onUpload(LGT_IPFS_GATEWAY_PREFIX + IpfsHash);
        } catch (e) {
          inputRef.current.value = '';
        }
      }
      setLoading(false);
    }
  }, [onUpload]);

  return (
    <InputAdornment position="end">
      {!url && <IconButton
        aria-label="upload"
        onClick={() => inputRef.current?.click()}
        disabled={loading}
        {...props}
      >
        {loading ? <CircularProgress size={24} /> : <FileUploadIcon />}
      </IconButton>}
      {url && <IconButton
        aria-label="delete"
        onClick={() => onUpload('')}
        >
        <CancelIcon />
      </IconButton>}
      <input
        type="file"
        accept={accept}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
    </InputAdornment>
  );
}

export default UploadAdornment;
