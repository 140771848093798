import React from 'react'
import AnimatedLoadingSpinner from '../assets/AnimatedLoadingSpinner.svg'

export const Video = (props: React.HTMLProps<HTMLVideoElement> & { src: string}) => {
  let url = props.src;

  if (url?.includes('ipfs.legitimate.tech')) {
    url += '?stream=true';
  }

  if (url?.includes('ipfs://')) {
    url = url.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');
  }

  return (
    <video loop autoPlay muted playsInline {...props} poster={AnimatedLoadingSpinner}>
      <source src={url} type="video/mp4" />
    </video>
  );
};
