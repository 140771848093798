
export enum Role {
  ADMIN = 'admin',
  USER = '',
  ENCODER = 'encoder'
}

export const roleLabels = {
  'admin': 'Admin User',
  'encoder': 'Encoder User',
  '': 'User'
}
