import {BASE_URL} from './baseUrl';
import {NetworkRequestError} from './error';

export interface TLabel {
  name: string;
}

export interface TTag {
  updated_at: number;
  created_at: number;
  uuid: string;
  name?: string;
  notes?: string;
  template_id?: number | null;
  shareable: boolean;
  nft_chain_id?: number;
  nft_contract_address?: string;
  nft_token_id?: number;
  verifications: number;
  qc: boolean;
  tamper_enabled?: boolean;
  latest_counter?: number;
  latest_tamper?: string;
}

export type TUpdateParams = Partial<
  Pick<
    TTag,
    | 'notes'
    | 'template_id'
    | 'nft_chain_id'
    | 'nft_contract_address'
    | 'nft_token_id'
  >
>;

export interface TPaginationInfo {
  scaffold_url: string;
  first_url: string;
  prev_url: string;
  page_url: string;
  next_url: string;
  last_url: string;
  count: number;
  page: number;
  items: number;
  pages: number;
  last: number;
  in: number;
  from: number;
  to: number;
  prev?: number;
  next?: number;
}

export const getTags = async (
  authToken: string,
  page: number = 1,
  template_id?: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const urlSearchParams = new URLSearchParams({
      page: page.toString(),
      page_size: template_id ? '20000' : '500' // todo: fix rendering of paginated items
    });
    if (template_id) {
      urlSearchParams.append('template_id', template_id.toString());
    }
    const response = await fetch(
      `${serverBaseUrl}/tags?${urlSearchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    //Pagination Info
    const responseBody = await response.json();
    return {
      tags: responseBody.tags as TTag[],
      pagination: responseBody.pagination as TPaginationInfo,
    }
  } catch (e) {
    throw e;
  }
};

export const fetchTag = async (
  authToken: string,
  uuid: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/${uuid}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const updateTag = async (
  authToken: string,
  uuid: string,
  params: TUpdateParams,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/${uuid}`, {
      method: 'PUT',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tag: params,
      }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

interface TTransferred {
  transferred: number[];
}

export const transferTags = async (
  authToken: string,
  templateId: number,
  toTemplateId: number,
  startingSerial: number,
  endingSerial: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/transfer`, {
      method: 'POST',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        template_id: templateId,
        to_template_id: toTemplateId,
        starting_serial_number: startingSerial,
        ending_serial_number: endingSerial,
      }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTransferred = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}

export const deleteTag = async (
  authToken: string,
  uuid: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/${uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
  } catch (e) {
    throw e;
  }
};
