import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { TTemplate, activateTagsInTemplate } from "../../utils/network/templates";
import { useContext, useState } from "react";
import { AuthContext } from "../../utils/auth/useAuth";
import { TTag } from "../../utils/network/tags";

const ActivateTags = ({ template, totalSupply, disabled }: { template: TTemplate, totalSupply: number, disabled?: boolean }) => {
  const [isActivating, setIsActivating] = useState(false);
  const [activatedTags, setActivatedTags] = useState<TTag[]>([]);

  const { userAuth } = useContext(AuthContext);

  const handleMintNfts = async () => {
    if (!userAuth?.token) {
      return;
    }

    setIsActivating(true);

    const tagsList = await activateTagsInTemplate(userAuth?.token, template.id);
    setActivatedTags(tagsList);

    setIsActivating(false);
  }

  const canActivateTags = template?.nft_contract_address && totalSupply >= template?.tags_count && template?.auto_manage_tag_sequence;

  return (
    <Stack spacing={2} marginY={4}>
      <Typography variant="h4">5. Activate Legitimate Tags</Typography>
      {template?.auto_manage_tag_sequence && <Typography variant="body1">
        There are {template?.tags_count} Legitimate Tags ready to activate.
      </Typography>}
      {!template?.auto_manage_tag_sequence && <Alert severity="info">
        Auto serial number assignment is not enabled for this SKU. Please assign the item serial number when programming the tags.
      </Alert>}
      {activatedTags.length > 0 && <Alert severity="success">
        {activatedTags.length} Legitimate Tags activated. <br />
        {activatedTags.map((tag) => <>{tag.uuid}: {tag.nft_token_id}<br /></>)}
      </Alert>}
      <Button variant="contained" disabled={!canActivateTags || isActivating || disabled} onClick={handleMintNfts}
        startIcon={isActivating && <CircularProgress size={16} />}
      >Activate {template?.tags_count} Legitimate Tags</Button>
    </Stack>
  );
}

export default ActivateTags;
