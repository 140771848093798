export enum BASE_URL {
  PRODUCTION = 'https://metadata-api.legitimate.tech', // AWS Heroku Virginia
  STAGING = 'https://dev-metadata-api.legitimate.tech', // AWS Heroku Virginia (Staging)
}

export const baseUrlLabels: Record<string, string> = {
  [BASE_URL.PRODUCTION]: 'Production',
  [BASE_URL.STAGING]: 'Testing',
};

export enum NFT_SERVER_BASE_URL {
  PRODUCTION = 'https://api.legitimate.tech', // Production Wallet
  STAGING = 'https://dev-api.legitimate.tech', // Testing Wallet
}
