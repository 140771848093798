import { Box, Button, Container, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { DataGrid, GridRenderCellParams, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { TTagVerification, TagVerificationType, fetchTagVerifications } from "../utils/network/verifications";
import { getTemplates } from "../utils/network/templates";
import { useContext, useState } from "react";
import { AuthContext } from "../utils/auth/useAuth";
import CancelIcon from '@mui/icons-material/Cancel';

const VerificationTypeLabels = {
  [TagVerificationType.VERIFICATION_TYPE_MANUFACTURER_QC]: 'Quality Check',
  [TagVerificationType.VERIFICATION_TYPE_PROGRAMMING_QC]: 'Tag Programmed',
  [TagVerificationType.VERIFICATION_TYPE_USER_VIEW_TAP]: 'Tag Viewed',
}

const TagVerifications = () => {
  const { userAuth } = useContext(AuthContext);

  let [searchParams, setSearchParams] = useSearchParams();

  const skuId = searchParams.get('sku') || '';
  const uuid = searchParams.get('uuid') || '';

  const [selectedSku, setSelectedSku] = useState<string>(skuId || '');
  const [selectedUuid, setSelectedUuid] = useState<string>(uuid || '');

  const { data, isLoading } = useQuery({
    queryKey: ['TagVerifications', selectedUuid, selectedSku],
    queryFn: () => fetchTagVerifications(userAuth?.token || '', selectedSku ? Number(selectedSku) : 0, selectedUuid),
  })

  const { data: skus, isLoading: isSkusLoading } = useQuery({
    queryKey: ['SKUs'],
    queryFn: () => getTemplates(userAuth?.token || ''),
  })

  const handleSetSku = (sku: string) => {
    setSelectedSku(sku);
    setSelectedUuid('');

    const params = new URLSearchParams(searchParams);
    params.set('sku', sku);

    setSearchParams(params);
  }

  const handleSetUuid = (uuid: string) => {
    setSelectedUuid(uuid);

    const params = new URLSearchParams(searchParams);
    params.set('uuid', uuid);

    setSearchParams(params);
  }

  const columns = [
    {
      field: 'uuid',
      headerName: 'Tag ID',
      width: 180,
      type: 'string',
      renderCell: (params: GridRenderCellParams<TTagVerification, string>) => {
        return <Button
          onClick={() => handleSetUuid(params.row.uuid)}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'template_id',
      headerName: 'SKU ID',
      width: 100,
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'SKU Name',
      width: 250,
      type: 'string',
    },
    {
      field: 'nft_token_id',
      headerName: 'Serial',
      width: 100,
      type: 'number',
    },
    {
      field: 'created_at',
      headerName: 'Verified At',
      width: 180,
      type: 'dateTime',
      valueGetter: (params: GridValueGetterParams<TTagVerification>) => {
        return new Date(params.row.created_at);
      }
    },
    {
      field: 'tamper',
      headerName: 'Tamper Status',
      width: 120,
      type: 'number',
    },
    {
      field: 'counter',
      headerName: 'Read Counter',
      width: 120,
      type: 'number',
    },
    {
      field: 'verify_type',
      headerName: 'Verification Type',
      width: 200,
      type: 'string',
      valueGetter: (params: GridValueGetterParams<TTagVerification>) => {
        return VerificationTypeLabels[params.row.verify_type] || params.row.verify_type;
      }
    },
    {
      field: 'city',
      headerName: 'City and Region',
      width: 300,
      type: 'string',
      valueGetter: (params: GridValueGetterParams<TTagVerification>) => {
        return [params.row.city, params.row.region, params.row.country].filter(Boolean).join(', ') || 'Unknown';
      }
    }
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Verifications</Typography>
      <Box sx={{ height: 20, width: '100%' }} />
      <Stack direction="row" spacing={2}>
        <Select
          displayEmpty
          onChange={e => handleSetSku(e.target.value)}
          sx={{ width: 400 }}
          defaultValue={skuId}
          value={selectedSku}
          disabled={isSkusLoading}
        >
          <MenuItem key='none' value={''}>
            All SKUs
          </MenuItem>
          {skus?.map((sku) => (
            <MenuItem key={sku.id} value={sku.id}>
              {sku.id} - {sku.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Tag ID"
          value={selectedUuid}
          onChange={e => handleSetUuid(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSetUuid('')}
                  onMouseDown={() => handleSetUuid('')}
                  disabled={!selectedUuid}
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            )}
          }
        />
      </Stack>
      <Box sx={{ height: 20, width: '100%' }} />
      <DataGrid
        getRowId={(row) => row.created_at}
        rows={data?.verifications || []}
        slots={{ toolbar: GridToolbar }}
        autoHeight
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default TagVerifications
