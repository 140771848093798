interface ServerError {
  errors: Record<string, string[]>;
}

// required for parsing Rails errors
export const parseErrors = (err: ServerError) => {
  let errorOutput = '';

  Object.keys(err.errors).forEach((key) => {
    errorOutput += `${key} ${err.errors[key]}. `;
  });

  return errorOutput;
};

export class NetworkRequestError extends Error {
  error: ServerError;

  status?: number;

  constructor(error: ServerError, status?: number) {
    const message = parseErrors(error);
    // @ts-ignore
    super(message);
    this.error = error;
    this.status = status;
  }
}
