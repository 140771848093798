import { Contract, JsonRpcProvider } from "ethers";
import { LGTContractVersion } from "../network/contract";
import v3PsiMetadataABI from './v3PsiMetadataABI.json';
import v3PsiBaseUriABI from './v3PsiBaseUriABI.json';
import { unescapeJsonString } from "./unescapeJson";

export interface IErc721Metadata {
  symbol?: string;
  tokenName?: string;
  totalSupply?: number;
}

export interface IOnChainMetadataContract extends IErc721Metadata {
  nftTitle?: string;
  nftDescription?: string;
  nftImageUri?: string;
  nftAnimationUri?: string;
}

export interface IBaseUriMetadataContract extends IErc721Metadata {
  baseURI?: string;
}

export type TContractData = IOnChainMetadataContract & IBaseUriMetadataContract;

export interface IFetchContractData {
  contractAddress: string;
  provider: JsonRpcProvider;
  contractVersion: LGTContractVersion;
}

export const fetchPrimaryContractData = async ({ contractAddress, provider, contractVersion }: IFetchContractData): Promise<TContractData> => {

  if (contractVersion === LGTContractVersion.V3_PSI_ON_CHAIN_METADATA) {
    return fetchOnChainData({ contractAddress, provider });
  }

  if (contractVersion === LGTContractVersion.V3_PSI_BASE_URI || contractVersion === LGTContractVersion.V3_BASE_URI) {
    return fetchBaseUriData({ contractAddress, provider });
  }

  throw new Error(`Contract version ${contractVersion} is not supported`);
}

export const fetchOnChainData = async ({ contractAddress, provider}: { contractAddress: string, provider: JsonRpcProvider }): Promise<IOnChainMetadataContract> => {
  const contract = new Contract(contractAddress, v3PsiMetadataABI, provider);

  const metadata = {} as IOnChainMetadataContract;

  const nameTx = contract.name().then((tokenName) => {
    metadata.tokenName = tokenName;
  });

  const symbolTx = contract.symbol().then((symbol) => {
    metadata.symbol = symbol;
  });

  const titleTx = contract.nftTitle().then((nftTitle) => {
    metadata.nftTitle = unescapeJsonString(nftTitle);
  });

  const descriptionTx = contract.nftDescription().then((nftDescription) => {
    metadata.nftDescription = unescapeJsonString(nftDescription);
  });

  const imageTx = contract.nftImageUri().then((nftImageUri) => {
    metadata.nftImageUri = nftImageUri;
  });

  const animationTx = contract.nftAnimationUri().then((nftAnimationUri) => {
    metadata.nftAnimationUri = nftAnimationUri;
  });

  const totalSupply = contract.totalSupply().then((totalSupply) => {
    metadata.totalSupply = Number(totalSupply);
  });

  await Promise.all([nameTx, symbolTx, titleTx, descriptionTx, imageTx, animationTx, totalSupply])

  return metadata;
}

export const fetchBaseUriData = async ({ contractAddress, provider}: { contractAddress: string, provider: JsonRpcProvider }): Promise<IBaseUriMetadataContract> => {
  const contract = new Contract(contractAddress, v3PsiBaseUriABI, provider);

  const metadata = {} as IBaseUriMetadataContract;

  const nameTx = contract.name().then((tokenName) => {
    metadata.tokenName = tokenName;
  });

  const symbolTx = contract.symbol().then((symbol) => {
    metadata.symbol = symbol;
  });

  const baseURI = contract.baseURI().then((baseURI) => {
    metadata.baseURI = baseURI;
  }).catch(async (e) => {
    // previous contracts did not expose baseURI as a public variable
    // we need to get it from the minted NFTs and strip out the tokenID or locked state `/1` or `/locked`
    await contract.tokenURI(1).then((tokenURI) => {
      metadata.baseURI = tokenURI?.replace('/1', '').replace('/locked', '');
      console.log(tokenURI)
    })
  });

  const totalSupply = contract.totalSupply().then((totalSupply) => {
    metadata.totalSupply = Number(totalSupply);
  });

  await Promise.all([nameTx, symbolTx, baseURI, totalSupply])

  return metadata;
}
