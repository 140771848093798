import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './utils/auth/useAuth';
import { Stack, Typography, Button, Box, TextField, MenuItem, Alert, Link } from '@mui/material'
import { ReactComponent as Wordmark } from './assets/Wordmark.svg'
import { BASE_URL, baseUrlLabels } from './utils/network/baseUrl';
import { useForm } from 'react-hook-form';
import { createUser } from './utils/network/users';
import { useMutation } from '@tanstack/react-query';

interface ISignupScreenForm {
  email: string;
  password: string;
  baseUrl: BASE_URL;
}

function SignupScreen() {
  const { userAuth } = useContext(AuthContext);

  const { mutate, isPending, error, isSuccess } = useMutation({
    mutationFn: createUser
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ISignupScreenForm>({
    shouldUseNativeValidation: true,
  });

  const handleSignup = async (data: ISignupScreenForm) => {
    const { email, password, baseUrl } = data;
    window.localStorage.setItem('baseUrl', baseUrl);

    mutate({ email, password });
  };

  const getDefaultBaseUrl = () => {
    const savedBaseUrl = window.localStorage.getItem('baseUrl') as BASE_URL;
    // validate that the baseUrl is valid from the localStorage saved data
    if (Object.values(BASE_URL).includes(savedBaseUrl)) {
      return savedBaseUrl;
    } else {
      // if the saved baseUrl is invalid or doesn't exist, set it to the default
      return BASE_URL.PRODUCTION;
    }
  }

  if (userAuth?.token) {
    return <Navigate to="/skus" />
  }

  return (
    <Stack justifyContent="center" alignItems="center" height="calc(100vh - 70px)" display="flex" spacing={4}>
      <Typography variant="h3" sx={{ color: 'primary.main', textAlign: 'center' }}>Tag Management Dashboard</Typography>
      <Box sx={{ width: '100%', maxWidth: '300px', marginBottom: 6 }} >
        <form onSubmit={handleSubmit(handleSignup)}>
          <Stack spacing={2}>
            <TextField type="email"
              id="email"
              {...register('email', { required: true })}
              required
              autoFocus
              label="Email"
              placeholder="hello@legitimate.tech"
            />
            <TextField type="password"
              {...register('password', { required: true, validate: (value) => value.length > 5 })}
              id="password"
              required
              label="Password"
              placeholder="**********"
            />
            {error && <Alert severity='error'>{error.message}</Alert>}
            {isSuccess && <Alert severity='success'>
              Sign up successful! <br /><Link href="/">Please login with your new account.</Link>
            </Alert>}
            <Button type="submit" variant="contained" disabled={isPending || !isDirty || !isValid}>Sign Up</Button>
            <TextField
              {...register('baseUrl')}
              id="baseUrl"
              size='small'
              label="Server"
              required
              select
              defaultValue={getDefaultBaseUrl()}
            >
              <MenuItem value={BASE_URL.PRODUCTION}>
                {baseUrlLabels[BASE_URL.PRODUCTION]}
              </MenuItem>
              <MenuItem value={BASE_URL.STAGING}>
                {baseUrlLabels[BASE_URL.STAGING]}
              </MenuItem>
            </TextField>
          </Stack>
        </form>
      </Box>

      <Link href="https://legitimate.tech" target='_blank' key="logo">
        <Wordmark style={{ width: '200px' }} />
      </Link>
    </Stack>
  );
}

export default SignupScreen;
