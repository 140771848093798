import { Button, Container, IconButton, Typography } from "@mui/material"
import { useContext } from "react"
import { TTemplate, getTemplates } from "../utils/network/templates";
import { AuthContext } from "../utils/auth/useAuth";
import { DataGrid, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID } from "../utils/web3";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useQuery } from "@tanstack/react-query";

const SkuIndex = () => {
  const { userAuth } = useContext(AuthContext);

  const { data, isLoading } = useQuery({
    queryKey: ['SKUs'],
    queryFn: () => getTemplates(userAuth?.token || ''),
    enabled: !!userAuth,
  })

  const columns = [
    {
      field: 'id',
      headerName: 'SKU ID',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/skus/${params.value}`}
          startIcon={<EditIcon />}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      type: 'actions',
      width: 40,
      getActions: (params: GridValueGetterParams<TTemplate>) => {
        return [
          <IconButton disabled={!params.row.analytics_url} color="primary" href={params.row.analytics_url || ''} target="_blank">
            <LeaderboardIcon/>
          </IconButton>
        ]
      },
      field: 'analytics'
    },
    {
      field: 'tag_view_verifications_count',
      headerName: 'Tag Views',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'tags_count',
      headerName: 'Tags',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/tags?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 180,
      type: 'dateTime',
      valueGetter: (params: any) => {
        return new Date(params.row.created_at);
      }
    },
    {
      field: 'name',
      headerName: 'Item Name',
      width: 250,
      type: 'string',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 300,
      type: 'string',
    },
    {
      field: 'verified_tags_count',
      headerName: 'QC',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        if (params.row.tags_count === params.row.verified_tags_count) {
          return <CheckCircleIcon color="success" />
        }
        return `${params.row.verified_tags_count}/${params.row.tags_count}`
      }
    },
    {
      field: 'tag_verifications_count',
      headerName: 'Total Verifications',
      width: 150,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'external_tag_verifications_count',
      headerName: 'API',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'nft_chain_id',
      headerName: 'Chain',
      width: 140,
      type: 'string',
      valueGetter: (params: GridValueGetterParams<TTemplate, NETWORK>) => {
        if (!params.row.nft_chain_id) {
          return '';
        } else if (!Object.values(NETWORK).includes(params.row.nft_chain_id)) {
          return 'Unknown';
        }
        return NETWORK_NAME_BY_CHAIN_ID[params.row.nft_chain_id as NETWORK];
      },
    }
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">SKUs</Typography>
      <Button href="/skus/new" variant="contained" sx={{ my: 2 }}
        startIcon={<AddIcon />}
      >
        New SKU
      </Button>
      <DataGrid
        rows={data || []}
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default SkuIndex
