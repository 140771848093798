import React from 'react'
import {deepmerge} from '@mui/utils'
import {ThemeOptions, createTheme} from '@mui/material';
import {Shadows} from '@mui/material/styles/shadows';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

/**
 * The default theme.
 */

const headingFont = ['PSTimes', 'Georgia', 'serif'].join(', ');
const bodyFont = ['Favorit', 'Arial', 'sans-serif'].join(', ');

const baseTheme: ThemeOptions = {
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    h1: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    h2: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    h3: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    h4: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    h5: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    h6: {
      fontFamily: headingFont,
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: bodyFont,
    },
    body1: {
      fontFamily: bodyFont,
      fontWeight: 400,
    },
    body2: {
      fontFamily: bodyFont,
      fontWeight: 400,
    },
    button: {
      fontSize: '15px',
      fontWeight: 700,
      lineHeight: '26px',
    },
    caption: {
      fontSize: '12px',
    },
    allVariants: {
      fontFamily: bodyFont,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 24,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '2px',
        },
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: () => ({
          color: 'inherit'
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({theme}: any) => ({
          fontWeight: 700,
          color: theme.palette.common.white,
          textTransform: 'uppercase',
          fontFamily: bodyFont,
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
};

export const LightTheme = createTheme(deepmerge(baseTheme, {
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#5D5F5F',
    },
    tertiary: {
      main: '#635D5B',
    },
    error: {
      main: '#B81B30',
    },
  },
}));

export const DarkTheme = createTheme(deepmerge(baseTheme, {
  palette: {
    mode: 'dark',
    primary: {
      main: '#C6C6C6',
    },
    secondary: {
      main: '#FFFFFF',
    },
    tertiary: {
      main: '#FFFFFF',
    },
    error: {
      main: "#FFB3B1",
    },
  },
}))

export const DefaultTheme = LightTheme;
