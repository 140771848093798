import { BASE_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";

export interface TUser {
  id: number;
  updated_at: number;
  created_at: number;
  email: string;
  tags_count: number;
  templates_count: number;
  tag_verifications_count: number;
  external_tag_verifications_count: number;
}

export const getUsers = async (
  authToken: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/users`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TUser[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export interface TCreateUpdateUser {
  email?: string;
  password?: string;
}

export const updateUser = async (
  authToken: string,
  userId: number,
  user: TCreateUpdateUser,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/users/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TUser = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}

export const createUser = async (
  user: TCreateUpdateUser,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl || BASE_URL.PRODUCTION}/users/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TUser = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}
