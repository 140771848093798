import { NETWORK } from "../web3";
import { NetworkRequestError } from "./error";

interface GasLevel {
  suggestedMaxPriorityFeePerGas: string;
  suggestedMaxFeePerGas: string;
  minWaitTimeEstimate: number;
  maxWaitTimeEstimate: number;
}

interface GasPrice {
  low: GasLevel;
  medium: GasLevel;
  high: GasLevel;
  estimatedBaseFee: string;
  networkCongestion: number;
  latestPriorityFeeRange: string[];
  historicalPriorityFeeRange: string[];
  historicalBaseFeeRange: string[];
  priorityFeeTrend: "up" | "down" | "neutral";
  baseFeeTrend: "up" | "down" | "neutral";
}

export const getGasPrice = async (
  chainId: NETWORK
) => {
  if (chainId === NETWORK.GNOSIS) {
    return {
      low: {
        suggestedMaxPriorityFeePerGas: '0.5',
        suggestedMaxFeePerGas: '1',
        minWaitTimeEstimate: 0,
        maxWaitTimeEstimate: 0,
      },
      medium: {
        suggestedMaxPriorityFeePerGas: '0.5',
        suggestedMaxFeePerGas: '1.5',
        minWaitTimeEstimate: 0,
        maxWaitTimeEstimate: 0,
      },
      high: {
        suggestedMaxPriorityFeePerGas: '1',
        suggestedMaxFeePerGas: '2',
        minWaitTimeEstimate: 0,
        maxWaitTimeEstimate: 0,
      },
      estimatedBaseFee: '1',
      networkCongestion: 0,
      latestPriorityFeeRange: ['1'],
      historicalPriorityFeeRange: ['1'],
      historicalBaseFeeRange: ['1'],
      priorityFeeTrend: 'neutral',
      baseFeeTrend: 'neutral',
    };
  }

  try {
    const response = await fetch(
      `https://gas-api.metaswap.codefi.network/networks/${chainId}/suggestedGasFees`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }

    const responseBody: GasPrice = await response.json();

    return responseBody;
  } catch (e) {
    throw e;
  }
};
