import { NETWORK } from "../web3";
import { NetworkRequestError } from "./error";

const ChainToGasToken = {
  [NETWORK.AVALANCHE]: 'avalanche-2',
  [NETWORK.POLYGON]: 'matic-network',
  [NETWORK.ARBITRUM_ONE]: 'ethereum',
  [NETWORK.ARBITRUM_NOVA]: 'ethereum',
  [NETWORK.ETHEREUM]: 'ethereum',
  [NETWORK.BASE]: 'ethereum',
  [NETWORK.GNOSIS]: 'xdai',
  [NETWORK.LINEA]: 'ethereum',
  [NETWORK.ZKSYNC]: 'ethereum',
} as Record<NETWORK, string>;

interface TokenPrice {
  symbol: string;
  name: string;
  market_data: {
    current_price: {
      usd: number;
    };
    ath: {
      usd: number;
    };
  }
}

const COIN_GECKO_API = 'https://api.coingecko.com/api/v3/coins/';
const COIN_GECKO_DEMO_API_KEY = 'CG-YCLG2CgyypNjvBy2W6HRLK4h'

export const getTokenPrice = async (chainId: NETWORK) => {
  const response = await fetch(
    `${COIN_GECKO_API}${ChainToGasToken[chainId]}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-cg-demo-api-key': COIN_GECKO_DEMO_API_KEY,
      },
    },
  );
  if (!response.ok) {
    throw new NetworkRequestError(await response.json(), response.status);
  }

  const responseBody: TokenPrice = await response.json();

  return responseBody;
}
