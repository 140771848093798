import React, { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from './utils/auth/useAuth';
import { Stack, Typography, Button, Box, TextField, MenuItem, Alert, Link } from '@mui/material'
import { ReactComponent as Wordmark } from './assets/Wordmark.svg'
import { BASE_URL, baseUrlLabels } from './utils/network/baseUrl';
import { useForm } from 'react-hook-form';

interface ILoginScreenForm {
  email: string;
  password: string;
  baseUrl: BASE_URL;
}

function LoginScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const { login, error, userAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<ILoginScreenForm>({
    shouldUseNativeValidation: true,
  });

  const handleLogin = async (data: ILoginScreenForm) => {
    setIsLoading(true);
    const { email, password, baseUrl } = data;
    window.localStorage.setItem('baseUrl', baseUrl);

    const loginSuccess = await login(email, password);
    if (loginSuccess) {
      navigate('/skus');
    }

    setIsLoading(false);
  };

  const getDefaultBaseUrl = () => {
    const savedBaseUrl = window.localStorage.getItem('baseUrl') as BASE_URL;
    // validate that the baseUrl is valid from the localStorage saved data
    if (Object.values(BASE_URL).includes(savedBaseUrl)) {
      return savedBaseUrl;
    } else {
      // if the saved baseUrl is invalid or doesn't exist, set it to the default
      return BASE_URL.PRODUCTION;
    }
  }

  if (userAuth?.token) {
    return <Navigate to="/skus" />
  }

  return (
    <Stack justifyContent="center" alignItems="center" height="calc(100vh - 70px)" display="flex" spacing={4}>
      <Typography variant="h3" sx={{ color: 'primary.main', textAlign: 'center' }}>Tag Management Dashboard</Typography>

      <Box sx={{ width: '100%', maxWidth: '300px', marginBottom: 6 }} >
        <form onSubmit={handleSubmit(handleLogin)}>
          <Stack spacing={2}>
            <TextField type="email"
              id="email"
              {...register('email', { required: true })}
              required
              autoFocus
              label="Email"
              placeholder="hello@legitimate.tech"
            />
            <TextField type="password"
              {...register('password', { required: true, validate: (value) => value.length > 5})}
              id="password"
              required
              label="Password"
              placeholder="**********"
            />
            {error && <Alert severity='error'>{error}</Alert>}
            <Button type="submit" variant="contained" disabled={isLoading || !isDirty|| !isValid}>Login</Button>
            <TextField
              {...register('baseUrl')}
              id="baseUrl"
              size='small'
              label="Server"
              required
              select
              defaultValue={getDefaultBaseUrl()}
            >
              <MenuItem value={BASE_URL.PRODUCTION}>
                {baseUrlLabels[BASE_URL.PRODUCTION]}
              </MenuItem>
              <MenuItem value={BASE_URL.STAGING}>
                {baseUrlLabels[BASE_URL.STAGING]}
              </MenuItem>
            </TextField>
          </Stack>
        </form>
      </Box>

      <Link href="https://legitimate.tech" target='_blank' key="logo">
        <Wordmark style={{ width: '200px' }} />
      </Link>
    </Stack>
  );
}

export default LoginScreen;
