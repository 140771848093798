import { useQuery } from "@tanstack/react-query"
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID } from "../utils/web3"
import { getGasPrice } from "../utils/network/gas";
import { Fab, Tooltip, Typography } from "@mui/material";

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const GasInfoByNetwork = {
  [NETWORK.ETHEREUM]: {
    lowMinimum: 10, // historic minimums for gas
    lowLimit: 50, // max for low gas before indicator turns red and indicates high gas
    gasPageUrl: 'https://etherscan.io/gastracker',
    unit: 'Gwei',
    decimals: 0
  },
  [NETWORK.AVALANCHE]: {
    lowMinimum: 25,
    lowLimit: 50,
    gasPageUrl: 'https://snowscan.xyz/gastracker',
    unit: 'nAvax',
    decimals: 0
  },
  [NETWORK.POLYGON]: {
    lowMinimum: 50,
    lowLimit: 150,
    gasPageUrl: 'https://polygonscan.com/gastracker',
    unit: 'Gwei',
    decimals: 0
  },
  [NETWORK.ARBITRUM_ONE]: {
    lowMinimum: 0.01,
    lowLimit: 0.02,
    gasPageUrl: 'https://tokentool.bitbond.com/gas-price/arbitrum',
    unit: 'Gwei',
    decimals: 2
  },
  [NETWORK.ARBITRUM_NOVA]: {
    lowMinimum: 0.01,
    lowLimit: 0.02,
    gasPageUrl: '',
    unit: 'Gwei',
    decimals: 3
  },
  [NETWORK.BASE]: {
    lowMinimum: 0.001,
    lowLimit: 0.02,
    gasPageUrl: '',
    unit: 'Gwei',
    decimals: 3
  }
} as Record<NETWORK, { lowMinimum: number, lowLimit: number, gasPageUrl: string, unit: string, decimals: number }>;

const isSupported = (chainId: NETWORK) => {
  return GasInfoByNetwork[chainId] !== undefined;
}

const GasPriceIndicator = ({ chainId }: { chainId: NETWORK}) => {
  const { data: gasPrice, error } = useQuery({
    queryKey: ['GasPrice', chainId],
    queryFn: () => getGasPrice(chainId),
    refetchInterval: 15000,
    refetchOnWindowFocus: true,
    enabled: isSupported(chainId)
  })

  // gas api is only available for mainnets
  if (!gasPrice || error || !chainId || !isSupported(chainId)) {
    return null;
  }

  const suggestedMaxFeePerGas = Number(gasPrice.medium.suggestedMaxFeePerGas);
  const suggestedMaxPriorityFeePerGas = Number(gasPrice.medium.suggestedMaxPriorityFeePerGas);
  const currentGas = suggestedMaxFeePerGas + suggestedMaxPriorityFeePerGas;
  const isGasLow = currentGas < GasInfoByNetwork[chainId].lowLimit;

  const tooltipMessage = isGasLow ? `Transaction costs are currently normal between ${GasInfoByNetwork[chainId].lowMinimum}-${GasInfoByNetwork[chainId].lowLimit} ${GasInfoByNetwork[chainId].unit} and the ${NETWORK_NAME_BY_CHAIN_ID[chainId]} network is stable. Please proceed with your transactions.`
    : `Transaction costs are high and the ${NETWORK_NAME_BY_CHAIN_ID[chainId]} network is congested. We suggest waiting for the network to stabilize under ${GasInfoByNetwork[chainId].lowLimit} ${GasInfoByNetwork[chainId].unit}.`;

  return (
    <Tooltip title={<Typography variant="body1">{tooltipMessage}</Typography>} arrow>
      <Fab variant="extended" color={isGasLow ? "success" : "error"} sx={{ position: 'fixed', bottom: 20, right: 20, color: 'white', textTransform: 'none' }}>
        <LocalGasStationIcon sx={{ mr: 1 }} />
          {currentGas.toFixed(GasInfoByNetwork[chainId].decimals)} {GasInfoByNetwork[chainId].unit}
      </Fab>
    </Tooltip>
  )
}

export default GasPriceIndicator;
