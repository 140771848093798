import { useState, createContext, useEffect } from 'react';
import { TUserAuth } from '../network/auth';
import { BASE_URL } from '../network/baseUrl';
import { parseErrors } from '../network/error';

interface IAuth {
  error: string;
  login: (
    email: string,
    password: string,
    baseUrlOverride?: BASE_URL
  ) => Promise<boolean>;
  logout: () => void;
  userAuth: TUserAuth | null;
}

const SIX_HOURS_IN_MS = 1000 * 60 * 60 * 6;

export const AuthContext = createContext<IAuth>({
  error: '',
  login: async () => false,
  logout: () => {},
  userAuth: null,
});

export const isNotExpired = (expiresAtRubyTimestamp: number) => {
  // JS time is in milliseconds, but our Rails API is in seconds
  // assume expired if expiration is < 6 hours from now
  return new Date() < new Date((expiresAtRubyTimestamp * 1000) - SIX_HOURS_IN_MS);
}

const useAuth: () => IAuth = () => {
  const [userAuth, setUserAuth] = useState<TUserAuth | null>(JSON.parse(window?.localStorage?.getItem('userAuth')!));
  const [error, setError] = useState<string>('');
  const [logoutTimeout, setLogoutTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (userAuth) {
      // The tokens typically expire after 24 hours so we need to check expiration
      // JS time is in milliseconds, but our Rails API is in seconds
      if (isNotExpired(userAuth.exp)) {
        setUserAuth(userAuth);
        startLogoutTimer(userAuth.exp);
      } else {
        logout();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => clearTimeout(logoutTimeout!);
  }, [logoutTimeout]);

  // automatically log out user when the 24 hour session token expires
  const startLogoutTimer = (expiresAtRubyTimestamp: number) => {
    const timeUntilExpiration = (expiresAtRubyTimestamp * 1000) - new Date().getTime();
    setLogoutTimeout(setTimeout(logout, timeUntilExpiration));
  }

  const login = async (
    email: string,
    password: string,
    baseUrl: BASE_URL = window.localStorage.getItem('baseUrl') as BASE_URL || BASE_URL.PRODUCTION
  ) => {

    try {
      const response = await fetch(`${baseUrl}/auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        // @ts-ignore
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (!response.ok) {
        const errors = await response.json();

        setError(parseErrors(errors));
        return false;
      }

      const responseBody: TUserAuth = await response.json();
      setUserAuth(responseBody);
      startLogoutTimer(responseBody.exp);
      setError('');
      window.localStorage.setItem('userAuth', JSON.stringify(responseBody));
      return true;
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }

    return false;
  };

  const logout = () => {
    setUserAuth(null);
    window.localStorage.removeItem('userAuth');
  }

  return {
    error,
    login,
    logout,
    userAuth,
  };
};

export default useAuth;
