export const unescapeJsonString = (str: string) => {
  // because our contract saves the description as a string and manually constructs the JSON,
  // we need to escape the quotes, new lines, etc. before it is saved so the end JSON is valid

  // this is used in conjunction with the `escapeJsonString` function on the NFT server when writing to the contract

  // when we load the string directly from the contract, we need to unescape it
  const jsonParse = JSON.parse(`{ "description": "${str}" }`);
  return jsonParse.description;
};
