import { Button, Container, Stack, TextField, Alert, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { TTemplate, createTemplate } from "../utils/network/templates";
import { AuthContext } from "../utils/auth/useAuth";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { LGTContractVersion } from "../utils/network/contract";

const STARTING_METADATA = {
  "data": [
    {
      "type": "claim_nft",
      "options": {},
      "version": "2.0.0"
    },
    {
      "type": "unlock_nft",
      "options": {},
      "version": "2.0.0"
    },
    {
      "type": "nft",
      "options": {},
      "version": "2.0.0"
    }
  ],
  "type": "lgt_content",
  "options": {},
  "version": "2.0.0"
}

type TCreateSku = Pick<TTemplate, 'name' | 'notes'>;

const CreateSku = () => {
  const { userAuth } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreateNewSku = async (data: TCreateSku) => {

    if (!userAuth?.token) {
      return;
    }

    setIsLoading(true);
    const { name, notes } = data;
    const metadata = STARTING_METADATA;
    const template = {
      name,
      notes,
      metadata,
      auto_manage_tag_sequence: true,
      nft_chain_id: 43114,
      contract_version: LGTContractVersion.V3_PSI_ON_CHAIN_METADATA
    } as TTemplate;

    try {
      const resp = await createTemplate(userAuth?.token, template);

      if (resp) {
        navigate(`/skus/${resp.id}`);
      }
    } catch (e: any) {
      setError(e.message || e);
    }

    setIsLoading(false);
  }

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<TCreateSku>({
    shouldUseNativeValidation: true,
  });

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">New SKU</Typography>
      <form onSubmit={handleSubmit(handleCreateNewSku)}>
          <Stack spacing={2} marginY={4}>
            <TextField type="text"
              id="name"
              required
              autoFocus
              label="Item Name"
              autoComplete="off"
              {...register("name", { required: true })}
              placeholder="0xAvenue PHYSICALS v1"
              helperText="This is the display name of the item and can also be used for the title of associated NFT. Max 50 characters."
              inputProps={{ maxLength: 50 }}
            />
            <TextField type="text"
              id="notes"
              label="Notes"
              multiline
              {...register("notes")}
              placeholder="Season 1 of the 0xAvenue PHYSICALS collection, exclusive to friends and family."
              helperText="Use this to add any additional notes about this item. This will not be displayed publicly."
            />
            {error && <Alert severity='error'>{error}</Alert>}
            <Button type="submit" variant="contained" disabled={isLoading || !isDirty}>Create New SKU</Button>
          </Stack>
        </form>
    </Container>
  )
}

export default CreateSku
