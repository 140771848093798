import { Alert, AlertTitle, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { deleteTemplate, getTemplates, transferTemplate, TTemplate } from "../../utils/network/templates";
import { useContext, useState } from "react";
import { AuthContext } from "../../utils/auth/useAuth";
import { useNavigate,  } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { transferTags } from "../../utils/network/tags";

const ManageTags = ({ template }: { template: TTemplate }) => {
  const [isTransferringOrDeleting, setIsTransferringOrDeleting] = useState(false);
  const [receivingUserId, setReceivingUserId] = useState<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [startingSerial, setStartingSerial] = useState<string>();
  const [endingSerial, setEndingSerial] = useState<string>();
  const [transferStatus, setTransferStatus] = useState<string>('');
  const navigate = useNavigate();

  const { userAuth } = useContext(AuthContext);

  const { data: skus } = useQuery({
    queryKey: ['SKUs'],
    queryFn: () => getTemplates(userAuth?.token || ''),
    enabled: !!userAuth,
  })

  const handleTransferTags = async () => {
    if (!userAuth?.token || !selectedTemplate) {
      return;
    }

    setTransferStatus('Transferring…');

    if (Number(startingSerial) > Number(endingSerial)) {
      setTransferStatus('Starting serial number cannot be greater than the ending serial number.');
    }

    if (!window.confirm(`Are you sure you want to transfer tags ${startingSerial}-${endingSerial} from SKU ${template.id} to SKU ${selectedTemplate}?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      const tags = await transferTags(userAuth?.token, template.id, Number(selectedTemplate), Number(startingSerial), Number(endingSerial));
      setTransferStatus(`${tags.transferred.length} tags successfully transferred - [${tags.transferred.sort().join(', ')}]`);
    } catch (e) {
      setTransferStatus("Something went wrong: " + JSON.stringify(e));
    }

    setIsTransferringOrDeleting(false);
  }

  const handleDeleteSKU = async () => {
    if (!userAuth?.token) {
      return;
    }

    if (!window.confirm(`Are you sure you want to delete SKU ${template.id}?\nThis action is irreversible!`)) {
      return;
    }

    if (!window.confirm(`All ${template.tags_count} tags within this SKU will be deleted.\nDo you want to proceed?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      await deleteTemplate(userAuth?.token, template.id);
      navigate('/skus');
    } catch (e) {
      console.error(e);
    }

    setIsTransferringOrDeleting(false);
  }

  const handleTransferSKU = async () => {
    if (!userAuth?.token || !receivingUserId) {
      return;
    }

    if (!window.confirm(`Are you sure you want to transfer SKU ${template.id} to User ID ${receivingUserId}?`)) {
      return;
    }

    setIsTransferringOrDeleting(true);

    try {
      await transferTemplate(userAuth?.token, template.id, Number(receivingUserId));
      navigate('/skus');
    } catch (e) {
      console.error(e);
    }

    setIsTransferringOrDeleting(false)
  }

  return (
    <Stack spacing={2} marginY={4}>
      <Typography variant="h4">6. Manage Tags</Typography>
      <Alert severity="warning">
        <AlertTitle variant="subtitle1">Transfer Tags</AlertTitle>
        <Typography variant="body2">Transfer a range of tags by serial number to a different SKU.</Typography>
        <br />
        <TextField
          select
          label="Select SKU to Transfer to"
          size="small"
          onChange={e => setSelectedTemplate(e.target.value)}
          fullWidth
          required
          disabled={false}
        >
          <MenuItem key='none' value={''}>
            Select SKU
          </MenuItem>
          {skus?.map((sku) => (
            <MenuItem key={sku.id} value={sku.id}>
              {sku.id} - {sku.name}
            </MenuItem>
          ))}
        </TextField>
        <br /><br />
        <Stack spacing={2} direction="row">
          <TextField type="number"
            label="Starting Tag Serial Number"
            required
            size="small"
            autoComplete="off"
            onChange={e => setStartingSerial(e.target.value)}
            value={startingSerial}
            disabled={isTransferringOrDeleting}
            helperText="This is the first tag in the range of tags to transfer, inclusive of this tag."
          />
          {<TextField type="number"
            label="Ending Tag Serial Number"
            required
            size="small"
            onChange={e => setEndingSerial(e.target.value)}
            value={endingSerial}
            autoComplete="off"
            disabled={isTransferringOrDeleting}
            helperText="This is the last tag in the range of tags to transfer, inclusive of this tag."
          />}
        </Stack>
        {transferStatus && <><br />{transferStatus}<br /></>}
        <br />
        <Button variant="outlined" color="warning"
          disabled={isTransferringOrDeleting || !selectedTemplate || !startingSerial || !endingSerial}
          onClick={handleTransferTags}
          size="small"
          startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Transfer Tags{startingSerial && endingSerial ? ` ${startingSerial}-${endingSerial}` : ''}
        </Button>
      </Alert>
      <Alert severity="warning">
        <AlertTitle variant="subtitle1">Transfer SKU</AlertTitle>
        <Typography variant="body2">Transfer this SKU and all associated tags to another user.</Typography>
        <br />
        <TextField
          size="small"
          type="number"
          value={receivingUserId}
          onChange={e => setReceivingUserId(e.target.value)}
          label="Receiving User"
          required
          autoComplete="off"
          placeholder="88"
          fullWidth
          helperText="Enter the User ID to transfer this SKU. If you do not know the User ID, please contact Legitimate."
          disabled={isTransferringOrDeleting}
        />
        <br /><br />
        <Button variant="outlined" size="small" color="warning" disabled={isTransferringOrDeleting || !receivingUserId} onClick={handleTransferSKU}
           startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Transfer SKU
        </Button>
      </Alert>
      <Alert severity="error">
        <AlertTitle variant="subtitle1">Delete SKU {template.id}</AlertTitle>
        <Typography variant="body2">All {template.tags_count} tags associated with this SKU will be deleted
          and cannot be verified by Legitimate in the future. <br />
          These tags cannot be reprogrammed or erased after being deleted. This action is irreversible.
        </Typography>
        <br />
        <Button size="small" variant="outlined" color="error" disabled={isTransferringOrDeleting} onClick={handleDeleteSKU}
           startIcon={isTransferringOrDeleting && <CircularProgress size={16} />}
        >
          Delete SKU
        </Button>
      </Alert>
    </Stack>
  );
}

export default ManageTags;
