import { Box, Container, InputAdornment, Link, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID } from "../utils/web3";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getGasPrice } from "../utils/network/gas";
import { getTokenPrice } from "../utils/network/tokenPrice";

const PriceEnabledChains = [
  NETWORK.AVALANCHE,
  NETWORK.POLYGON,
  NETWORK.ETHEREUM,
  NETWORK.BASE,
  NETWORK.ARBITRUM_ONE,
  NETWORK.ARBITRUM_NOVA,
  NETWORK.GNOSIS,
  NETWORK.LINEA,
]

const GasRangeByChain = {
  [NETWORK.AVALANCHE]: [25, 200],
  [NETWORK.POLYGON]: [50, 1000],
  [NETWORK.ETHEREUM]: [15, 500],
  [NETWORK.BASE]: [0.02, 10],
  [NETWORK.ARBITRUM_ONE]: [0.01, 2],
  [NETWORK.ARBITRUM_NOVA]: [0.01, 0.1],
  [NETWORK.GNOSIS]: [1, 5],
  [NETWORK.LINEA]: [0.02, 5],
} as Record<NETWORK, [number, number]>;

const MINT_100_GAS = 2620000;
const MINT_MIN = 120000;
const DEPLOY_GAS = 3110000;
const TRANSFER_GAS = 100000;
const METADATA_UPDATE_GAS = 600000;

const TOKEN_LOW_PRICE_MULTIPLIER = 0.5;

const GWEI_TO_ETH = 1e9;

const GasCalculator = () => {
  const [selectedChain, setSelectedChain] = useState<NETWORK>(NETWORK.AVALANCHE);
  const [estimatedTokenPrice, setEstimatedTokenPrice] = useState<number>(0);
  const [estimatedGasPrice, setEstimatedGasPrice] = useState<number>(0);
  const [numNfts, setNumNfts] = useState<number>(1000);

  const { data: tokenPrice } = useQuery({
    queryKey: ['tokenPrice', selectedChain],
    queryFn: async () => {
      const token = await getTokenPrice(selectedChain);
      setEstimatedTokenPrice(Number(token.market_data.current_price.usd.toFixed(2)));
      return token
    },
  })

  useQuery({
    queryKey: ['GasPrice', selectedChain],
    queryFn: async () => {
      const gas = await getGasPrice(selectedChain)
      const total = Number(gas.medium.suggestedMaxFeePerGas) + Number(gas.medium.suggestedMaxPriorityFeePerGas);
      setEstimatedGasPrice(Number(total.toPrecision(4)));
      return gas;
    }
  })

  return <Container maxWidth="md">
    <Stack spacing={2}>
      <Typography variant="h2">Gas Calculator</Typography>
      <Typography variant="h4">Token and Gas Cost</Typography>
      <TextField
        select
        onChange={e => setSelectedChain(Number(e.target.value) as NETWORK)}
        defaultValue={NETWORK.AVALANCHE}
        value={selectedChain}
        label="Blockchain"
        helperText="Select the blockchain to estimate gas costs for."
      >
        {PriceEnabledChains.map((chainId) => (
          <MenuItem key={chainId} value={chainId}>
            {NETWORK_NAME_BY_CHAIN_ID[chainId]}
          </MenuItem>
        ))}
      </TextField>
      <TextField label="Token Price"
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">USD / {tokenPrice?.symbol.toUpperCase()}</InputAdornment>,
        }}
        value={estimatedTokenPrice}
        onChange={e => setEstimatedTokenPrice(Number(e.target.value))}
        helperText={`Current market price of the token in USD. Historically ${tokenPrice?.symbol.toUpperCase()} is between $${((tokenPrice?.market_data.current_price.usd || 0) * TOKEN_LOW_PRICE_MULTIPLIER).toFixed(2)} and $${tokenPrice?.market_data.ath.usd.toFixed(2)}`}
      />
      <TextField label="Gas Price (Instant)"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">gwei / gas unit</InputAdornment>,
        }}
        value={estimatedGasPrice}
        onChange={e => setEstimatedGasPrice(Number(e.target.value))}
        helperText={`Current gas price for guaranteed on-chain execution in the next block. Historically, ${NETWORK_NAME_BY_CHAIN_ID[selectedChain]} is between ${GasRangeByChain[selectedChain][0]} and ${GasRangeByChain[selectedChain][1]} gwei / gas unit.`}
      />
      <TextField label="Number of Items"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">items</InputAdornment>,
        }}
        value={numNfts}
        onChange={e => setNumNfts(Number(e.target.value))}
        helperText={`Number of items to mint NFTs for.`}
      />
      <Typography variant="body1">
        Please refer to <Link href="https://www.coingecko.com/" target="_blank">CoinGecko</Link> for current and historic token prices.
        Gas prices can be found on <Link href="https://owlracle.info/" target="_blank">Owlracle</Link>.
      </Typography>
      <Typography variant="h4">Estimated Cost</Typography>
      <TextField label="Total Cost to Mint and Transfer NFTs in USD"
        read-only
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">USD for 1 SKU with {numNfts} items</InputAdornment>,
        }}
        value={(estimatedGasPrice * ((numNfts * TRANSFER_GAS) + Math.max((numNfts * MINT_100_GAS / 100), MINT_MIN) + METADATA_UPDATE_GAS + DEPLOY_GAS) * estimatedTokenPrice / GWEI_TO_ETH).toFixed(2)}
        helperText={`Estimate includes NFT contract deployment, metadata updates, minting, and transfers.`}
      />
      <TextField label="Total Cost to Mint and Transfer NFTs in Tokens"
        read-only
        InputProps={{
          endAdornment: <InputAdornment position="end">{tokenPrice?.symbol.toUpperCase()} for 1 SKU with {numNfts} items</InputAdornment>,
        }}
        value={(estimatedGasPrice * ((numNfts * TRANSFER_GAS) + Math.max((numNfts * MINT_100_GAS / 100), MINT_MIN) + METADATA_UPDATE_GAS + DEPLOY_GAS) / GWEI_TO_ETH).toPrecision(4)}
        helperText={`Estimate includes NFT contract deployment, metadata updates, minting, and transfers.`}
      />
      <Typography variant="body1">
        Actual cost can range from <strong>{(GasRangeByChain[selectedChain][0] * ((numNfts * TRANSFER_GAS) + Math.max((numNfts * MINT_100_GAS / 100), MINT_MIN) + METADATA_UPDATE_GAS + DEPLOY_GAS) * estimatedTokenPrice * TOKEN_LOW_PRICE_MULTIPLIER / GWEI_TO_ETH).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong>
        {' '}to <strong>{(GasRangeByChain[selectedChain][1] * ((numNfts * TRANSFER_GAS) + Math.max((numNfts * MINT_100_GAS / 100), MINT_MIN) + METADATA_UPDATE_GAS + DEPLOY_GAS) * Number(tokenPrice?.market_data.ath.usd) / GWEI_TO_ETH).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong>
        {' '}depending on gas price and token price at the time of use.
      </Typography>
      <Typography variant="h4">USD Cost Breakdown</Typography>
      <TextField label="Cost to Deploy Contract"
        read-only
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">/ SKU</InputAdornment>,
        }}
        value={(estimatedGasPrice * DEPLOY_GAS * estimatedTokenPrice / GWEI_TO_ETH).toFixed(2)}
        helperText={`Deployment cost for an NFT contract is about ${DEPLOY_GAS.toLocaleString()} gas units.`}
      />
      <TextField label="Cost to Write NFT Metadata On-Chain"
        read-only
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">/ SKU</InputAdornment>,
        }}
        value={(estimatedGasPrice * METADATA_UPDATE_GAS * estimatedTokenPrice / GWEI_TO_ETH).toFixed(2)}
        helperText={`Average cost for a SKU is about ${METADATA_UPDATE_GAS.toLocaleString()} gas units. Cost varies based on data size such as name, description, image, and animation.`}
      />
      <TextField label="Cost to Mint NFTs"
        read-only
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">/ {numNfts} NFTs</InputAdornment>,
        }}
        value={(estimatedGasPrice * Math.max((numNfts * MINT_100_GAS / 100), MINT_MIN) * estimatedTokenPrice / GWEI_TO_ETH).toFixed(2)}
        helperText={`Average cost for 100 NFTs is about ${MINT_100_GAS.toLocaleString()} gas units. Minimum cost is about ${MINT_MIN.toLocaleString()} gas units regardless of the number of NFTs.`}
      />
      <TextField label="Cost to Transfer NFTs"
        read-only
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">/ {numNfts} transfers</InputAdornment>,
        }}
        value={(estimatedGasPrice * numNfts * TRANSFER_GAS * estimatedTokenPrice / GWEI_TO_ETH).toFixed(2)}
        helperText={`Average cost to transfer an NFT is about ${TRANSFER_GAS.toLocaleString()} gas units. Each NFT is assumed to be transferred once.`}
      />
      <Typography variant="body1">
        Cost in USD = gas used x gas price x USD token price. 1 token = {1e9.toLocaleString()} gwei.
      </Typography>
      <Typography variant="body1">
      </Typography>
      <Box height={50} />
    </Stack>
  </Container>
}

export default GasCalculator
