import { Alert, Button, CircularProgress, Link, Stack, Typography } from "@mui/material"
import { TTemplate } from "../../utils/network/templates";
import { useContext, useState } from "react";
import { LGTContractVersion, TTransaction, mintNfts } from "../../utils/network/contract";
import { AuthContext } from "../../utils/auth/useAuth";
import { NETWORK, generateTxLink } from "../../utils/web3";
import { JsonRpcProvider } from "ethers";
import { TContractData } from "../../utils/web3/primaryContractData";

const MintNfts = ({ template, contractMetadata, onChange, provider, disabled }: { disabled: boolean, template: TTemplate, contractMetadata?: TContractData, onChange: () => void, provider?: JsonRpcProvider }) => {
  const [isMinting, setIsMinting] = useState(false);
  const [txProgress, setTxProgress] = useState<{ completed: TTransaction[], inProgress: TTransaction[]}>({
    completed: [],
    inProgress: []
  });
  const { userAuth } = useContext(AuthContext);

  const { totalSupply, nftTitle, nftDescription, nftImageUri, baseURI } = contractMetadata || {};

  const handleMintNfts = async () => {
    if (!userAuth?.token || totalSupply === undefined) {
      return;
    }

    setIsMinting(true);

    const quantity = template?.tags_count - totalSupply;

    const txList = await mintNfts(template.id, userAuth?.token, quantity,  template.nft_chain_id as NETWORK);

    await Promise.all(txList.map(async (tx) => {
      setTxProgress((prev) => ({
        inProgress: [...prev.inProgress, tx],
        completed: prev.completed
       }));
      await provider?.waitForTransaction(tx.hash) as TTransaction;
      setTxProgress((prev) => ({
        inProgress: prev.inProgress.filter((inProgressTx) => tx.hash !== inProgressTx.hash),
        completed: [...prev.completed, tx]
       }));
    }));

    onChange();
    setIsMinting(false);
  }

  const isBaseUri = template?.contract_version === LGTContractVersion.V3_BASE_URI || template?.contract_version === LGTContractVersion.V3_PSI_BASE_URI;
  const hasMetadata = isBaseUri ? !!baseURI : (nftTitle && nftDescription && nftImageUri);
  const canMintNfts = template?.nft_contract_address && (totalSupply || 0) < template?.tags_count;

  return (
    <Stack spacing={2} marginY={4}>
      <Typography variant="h4">4. Create Digital Product IDs</Typography>
      <Typography variant="body1">
        There are currently {totalSupply || 0} Digital Product IDs and {template?.tags_count} Legitimate Tags assciated with this SKU. {template?.tags_count - Number(totalSupply || 0)} digital product IDs need to be created.
      </Typography>
      {!hasMetadata && <Alert severity="info">
        {!isBaseUri && 'Product title, description, and image must all be added before creating digital product IDs.'}
        {isBaseUri && 'NFT base URI must be set before creating NFTs.'}
      </Alert>}
      {hasMetadata && template?.tags_count === 0 && <Alert severity="info">
        There are no Legitimate Tags programmed for this SKU.
      </Alert>}
      {txProgress.inProgress.length > 0 && <Alert severity="info">
        Digital product ID creation in progress...<br />
        {txProgress.inProgress.map((tx) => <><Link href={generateTxLink(template.nft_chain_id as NETWORK, tx.hash)} target="_blank">{tx.hash}</Link><br /></>)}
      </Alert>}
      {!!totalSupply && txProgress.inProgress.length === 0 && template.tags_count === totalSupply && hasMetadata && <Alert severity="success">
        All digital product IDs for the Legitimate Tags associated with this SKU have been created.
      </Alert>}
      <Button variant="contained" disabled={!hasMetadata || !canMintNfts || isMinting || disabled} onClick={handleMintNfts}
        startIcon={isMinting && <CircularProgress size={16} />}
      >Create {template?.tags_count - Number(totalSupply || 0)} Digital Product IDs</Button>
    </Stack>
  );
}

export default MintNfts;
