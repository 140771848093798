import { useContext } from "react";
import { AuthContext } from "../utils/auth/useAuth";
import { Alert, Button, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { TCreateUpdateUser, updateUser } from "../utils/network/users";

interface IUpdateUserForm extends TCreateUpdateUser {
  verifyPassword?: string;
}

const Profile = () => {
  const { userAuth } = useContext(AuthContext);

  const { isPending, mutate, error, isSuccess }= useMutation({
    mutationFn: async (user: TCreateUpdateUser) => {
      return await updateUser(userAuth?.token || '', userAuth?.data.user.id || 0, {
        email: user.email,
        password: user.password
      });
    }
  })

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    getValues,
  } = useForm<IUpdateUserForm>({
    shouldUseNativeValidation: true,
    values: {
      email: userAuth?.data.user.email || '',
    }
  });

  const handleSave = async (data: IUpdateUserForm) => {
    if (data.password !== data.verifyPassword) {
      return;
    }

    await mutate(data);
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Container maxWidth="md">
        <Stack spacing={2}>
          <Typography variant="h2">User Profile</Typography>
          <TextField
            label="Email"
            type="email"
            {...register('email')}
            helperText="This will be used for your login to this system."
          />
          <TextField
            type="password"
            {...register('password', {
              minLength: 6
            })}
            label="New Password"
            helperText="Minimum 6 characters"
          />
          <TextField
            type="password"
            label="Verify Password"
            {...register('verifyPassword', {
              validate: (value) => value === getValues('password')
            })}
            helperText="Please re-enter your password"
          />
          {error && <Alert severity="error">
            {error?.message}
          </Alert>}
          {isSuccess && <Alert severity="success">
            Profile updated successfully.
          </Alert>}
          <Button type="submit" disabled={!isDirty || !isValid || isPending}
            startIcon={isPending ? <CircularProgress size={16} /> : null}
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      </Container>
    </form>
  )

}

export default Profile
