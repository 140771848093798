import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  Outlet
} from 'react-router-dom';
import LoginScreen from './Login';
import useAuth, { AuthContext } from './utils/auth/useAuth';
import {ThemeProvider} from "@mui/material";
import { LightTheme } from './utils/theme/MuiTheme'
import NavigationBar from './NavigationBar'
import Skus from './Skus'
import Users from './Users'
import ViewSku from './Skus/View';
import CreateSku from './Skus/Create';
import Tags from './Tags';

import "./App.css"
import Profile from './Account/Profile';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useContext } from 'react';
import TagVerifications from './TagViews';
import SignupScreen from './Signup';
import GasCalculator from './Gas';

const PrivateRoutes = () => {
  const { userAuth } = useContext(AuthContext);

  return (
    userAuth?.token ? <Outlet/> : <Navigate to='/'/>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationBar />}>
      <Route element={<PrivateRoutes/>}>
        <Route path="/skus" element={<Skus />} />
        <Route path="/skus/new" element={<CreateSku />} />
        <Route path="/skus/:skuId" element={<ViewSku />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/views" element={<TagVerifications />} />
        <Route path="/users" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/gas" element={<GasCalculator />} />
    </Route>
  )
);

export default function App() {
  const queryClient = new QueryClient()
  const authState = useAuth();

  return (
    <ThemeProvider theme={LightTheme}>
      <AuthContext.Provider value={authState}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
