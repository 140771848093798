import { NFT_SERVER_BASE_URL } from "../network/baseUrl";

export enum NETWORK {
  ETHEREUM = 1,
  ETHEREUM_SEPOLIA = 11155111,
  POLYGON = 137,
  POLYGON_AMOY = 80002,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  ARBITRUM_ONE = 42161,
  ARBITRUM_ONE_SEPOLIA = 421614,
  ARBITRUM_NOVA = 42170,
  GNOSIS = 100,
  GNOSIS_CHAIDO = 10200,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
  LINEA = 59144,
  LINEA_TESTNET = 59140,
  ZKSYNC = 324,
  ZKSYNC_SEPOLIA = 300,
  LAMINA1 = 10849,
  LAMINA1_FUJI = 764984,
}

export const NFT_SERVER_BASE_URL_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.ETHEREUM_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.POLYGON]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.POLYGON_AMOY]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.AVALANCHE]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.AVALANCHE_FUJI]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.ARBITRUM_ONE]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.ARBITRUM_NOVA]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.GNOSIS]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.GNOSIS_CHAIDO]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.BASE]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.BASE_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.LINEA]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.LINEA_TESTNET]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.ZKSYNC]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.ZKSYNC_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.LAMINA1]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.LAMINA1_FUJI]: NFT_SERVER_BASE_URL.STAGING,
}

export const NETWORK_NAME_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: 'Ethereum',
  [NETWORK.ETHEREUM_SEPOLIA]: 'Ethereum Testnet',
  [NETWORK.POLYGON]: 'Polygon',
  [NETWORK.POLYGON_AMOY]: 'Polygon Testnet',
  [NETWORK.AVALANCHE]: 'Avalanche',
  [NETWORK.AVALANCHE_FUJI]: 'Avalanche Testnet',
  [NETWORK.ARBITRUM_ONE]: 'Arbitrum One',
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: 'Arbitrum One Testnet',
  [NETWORK.ARBITRUM_NOVA]: 'Arbitrum Nova',
  [NETWORK.GNOSIS]: 'Gnosis',
  [NETWORK.GNOSIS_CHAIDO]: 'Gnosis Testnet',
  [NETWORK.BASE]: 'Base',
  [NETWORK.BASE_SEPOLIA]: 'Base Testnet',
  [NETWORK.LINEA]: 'Linea',
  [NETWORK.LINEA_TESTNET]: 'Linea Testnet',
  [NETWORK.ZKSYNC]: 'ZkSync',
  [NETWORK.ZKSYNC_SEPOLIA]: 'ZkSync Testnet',
  [NETWORK.LAMINA1]: 'Lamina1',
  [NETWORK.LAMINA1_FUJI]: 'Lamina1 Testnet',
}

export const generateAddressLink = (chainId: NETWORK, address: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/address/${address}`;
};

export const generateTxLink = (chainId: NETWORK, transactionHash: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/tx/${transactionHash}`;
};

export const TX_BASE_URL_FOR_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "etherscan.io",
  [NETWORK.ETHEREUM_SEPOLIA]: "sepolia.etherscan.io",
  [NETWORK.POLYGON]: "polygonscan.com",
  [NETWORK.POLYGON_AMOY]: "amoy.polygonscan.com",
  [NETWORK.AVALANCHE]: "snowscan.xyz",
  [NETWORK.AVALANCHE_FUJI]: "testnet.snowscan.xyz",
  [NETWORK.ARBITRUM_ONE]: "arbiscan.io",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "sepolia.arbiscan.io",
  [NETWORK.ARBITRUM_NOVA]: "nova.arbiscan.io",
  [NETWORK.GNOSIS]: "gnosisscan.io",
  [NETWORK.GNOSIS_CHAIDO]: "gnosis-chiado.blockscout.com",
  [NETWORK.BASE]: "basescan.org",
  [NETWORK.BASE_SEPOLIA]: "sepolia.basescan.org",
  [NETWORK.LINEA]: "lineascan.build",
  [NETWORK.LINEA_TESTNET]: "sepolia.lineascan.build",
  [NETWORK.ZKSYNC]: "explorer.zksync.io",
  [NETWORK.ZKSYNC_SEPOLIA]: "epolia.explorer.zksync.io",
  [NETWORK.LAMINA1]: "subnets.avax.network/lamina1",
  [NETWORK.LAMINA1_FUJI]: "subnets-test.avax.network/lamina1tes",
};

export const RPC_URL_BY_CHAIN_ID = {
  [NETWORK.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
  [NETWORK.AVALANCHE_FUJI]: "https://api.avax-test.network/ext/bc/C/rpc",
  [NETWORK.ETHEREUM]: "https://rpc.ankr.com/eth/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.ETHEREUM_SEPOLIA]: "https://rpc.ankr.com/eth_sepolia/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON]: "https://rpc.ankr.com/polygon/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON_AMOY]: "https://rpc.ankr.com/polygon_amoy/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.ARBITRUM_ONE]: "https://arb1.arbitrum.io/rpc",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "https://sepolia-rollup.arbitrum.io/rpc",
  [NETWORK.ARBITRUM_NOVA]: "https://nova.arbitrum.io/rpc",
  [NETWORK.GNOSIS]: "https://rpc.gnosischain.com",
  [NETWORK.GNOSIS_CHAIDO]: "https://rpc.chiadochain.net",
  [NETWORK.BASE]: "https://mainnet.base.org",
  [NETWORK.BASE_SEPOLIA]: "https://sepolia.base.org",
  [NETWORK.LINEA]: "https://rpc.linea.build",
  [NETWORK.LINEA_TESTNET]: "https://rpc.sepolia.linea.build",
  [NETWORK.ZKSYNC]: "https://mainnet.era.zksync.io",
  [NETWORK.ZKSYNC_SEPOLIA]: "https://sepolia.era.zksync.dev",
  [NETWORK.LAMINA1]: "https://subnets.avax.network/lamina1/mainnet/rpc",
  [NETWORK.LAMINA1_FUJI]: "https://subnets.avax.network/lamina1tes/testnet/rpc",
}

export const TESTNETS = [
  NETWORK.ETHEREUM_SEPOLIA,
  NETWORK.POLYGON_AMOY,
  NETWORK.AVALANCHE_FUJI,
  NETWORK.ARBITRUM_ONE_SEPOLIA,
  NETWORK.GNOSIS_CHAIDO,
  NETWORK.BASE_SEPOLIA,
  NETWORK.LINEA_TESTNET,
  NETWORK.ZKSYNC_SEPOLIA,
  NETWORK.LAMINA1_FUJI,
];

export const isTestnet = (chainId: NETWORK) => TESTNETS.includes(chainId);
